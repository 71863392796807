
/* Add your profile image here */
.Photo{
    /*background-image: url('https://images.pexels.com/photos/3799821/pexels-photo-3799821.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');*/
    background-image: url('https://garvsachdev.com/garv.png?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
}
/* Projects Photo */
.project1{
    background-image: url('https://garvsachdev.com/Healert.jpeg?text=Project+one');
    cursor: none;    
}
.project2{
    background-image: url('https://garvsachdev.com/GavBot.jpeg?text=Project+two');
    cursor: none;
}
.project3{
    background-image: url('https://garvsachdev.com/SusBot.jpeg?text=Project+three');
    cursor: none;
}
.project4{
    background-image: url('https://garvsachdev.com/particle_simulator.png?text=Project+four');
    cursor: none;
}
.project5{
    background-image: url('https://garvsachdev.com/MusicBot.jpeg?text=Project+Five');
    cursor: none;
}
/*.project6{
    background-image: url('https://via.placeholder.com/400x600.png?text=Project+six');
    cursor: none;
} */
/* Miscelaneous Activities photo */
.misc1{
    background-image: url('https://garvsachdev.com/documents/Fine-Art-Painting4.jpeg?text=Miscellaneous+one');
    cursor: none;
}
.misc2{
    background-image: url('https://garvsachdev.com/documents/Fine-Art-Painting3.jpeg?text=Miscellaneous+two');
    cursor: none;
}
.misc3{
    background-image: url('https://garvsachdev.com/documents/Fine-Art-Painting2.jpeg?Miscellaneous+three');
    cursor: none;
}
.misc4{
    background-image: url('https://garvsachdev.com/documents/Fine-Art-Painting1.jpeg?Miscellaneous+three');
    cursor: none;
}
.misc5{
    background-image: url('https://garvsachdev.com/documents/Telephone-Lino-Print.jpeg?Miscellaneous+three');
    cursor: none;
}
.misc6{
    background-image: url('https://garvsachdev.com/cartoon-thumbnail.png?Miscellaneous+three');
    cursor: none;
}



/* Music  */
.music1{
    background-image: url('https://garvsachdev.com/apple-sake.jpeg?text=Miscellaneous+one');
    cursor: none;
}
.music2{
    background-image: url('https://garvsachdev.com/myfirstalbum.jpeg?text=Miscellaneous+two');
    cursor: none;
}
/* .music3{
    background-image: url('https://garvsachdev.com/documents/Fine-Art-Painting2.jpeg?Miscellaneous+three');
    cursor: none;
} */